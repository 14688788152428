import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Game } from '../interfaces';
import { Api } from '../services/api';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, InputLabel, MenuItem, Box, Paper, Select, Stack, styled, Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function GameList({ api }: { api: Api }) {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const startWeek = 35;

  const currentDate = moment();
  const weeks = [...Array(17).keys()].map(i => ({
    weekNumber: i + 1,
    weekStart: currentDate.clone().week(i + 1 + startWeek).startOf("week").day(4),
    weekEnd: currentDate.clone().week(i + 1 + startWeek).endOf("week").add(2, "days")
  }))

  const currentNflWeek = weeks.find(f => currentDate.isBetween(f.weekStart, f.weekEnd))?.weekNumber || weeks.find(f => currentDate.isBefore(f.weekStart))?.weekNumber || 1;

  const [week, setWeek] = useState<number>(currentNflWeek);

  const selectWeek = useCallback(async (event: any | number) => {

    var week = event.target?.value || event;
    setError(null)
    setWeek(week)
    if (week)
      try {
        setLoading(true);
        var games = await api.getGames(week)
        setGames(games)
      }
      catch (err: any) {
        console.error(err)
        setError(err)
      }
      finally {
        setLoading(false);
      }
  }, [api]);

  useEffect(() => {
    selectWeek(week);
  }, [week, selectWeek])


  // sort by dates
  const gamesByDate: { [date: string]: Game[] } = {};
  var hasToday = false;
  games.forEach(game => {
    const dt = moment(game.game_date)
    const date = dt.format('YYYY-MM-DD');
    if (!gamesByDate[date]) {
      gamesByDate[date] = [];
    }
    if (dt.dayOfYear() === currentDate.dayOfYear())
      hasToday = true;
    gamesByDate[date].push(game);
  });

  // sort gamesByDate keys and also values within each key
  Object.values(gamesByDate).forEach(games => games.sort((a, b) => moment(a.game_date).diff(moment(b.game_date))));

  return (
    <div>
      <h1>Games</h1>
      <Box sx={{ p: 1 }} >
        <form>
          <InputLabel id="week">Week</InputLabel>
          <Select labelId="week" label="week" value={week} onChange={selectWeek}>
            {
              weeks.map((week,i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  [{week.weekNumber}] {week.weekStart.format("MMM Do")} - {week.weekEnd.format("MMM Do")}
                </MenuItem>))}
          </Select>
        </form>
      </Box>
      {loading ?
        <Box sx={{ width: '100%', py: 2 }}>
          <LinearProgress />
        </Box>
        :
        error ?
          <Alert severity="error">
            Uh oh! Something went wrong...
          </Alert>
          :

          Object.keys(gamesByDate).map(gdkey => {
            const sortedGames = gamesByDate[gdkey]
            var expanded = hasToday ? moment(gdkey).dayOfYear() === currentDate.dayOfYear() : true
            return (
              <Accordion key={gdkey.toString()} defaultExpanded={expanded}>
                <AccordionSummary

                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id={`panel1-header-${gdkey}`}
                >
                  {moment(gdkey).format("MMMM Do, YYYY")}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    {sortedGames.map(game => (
                      <Item key={game.id}>
                        <Link to={`/games/${game.id}`}>
                          {game.away_team} @ {game.home_team} - {moment(game.game_date).format("MMMM Do YYYY @ h:mm a")}
                        </Link>
                      </Item>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )
          }
          )


      }
    </div >
  );
}

export default GameList;
