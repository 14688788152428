import axios, { Axios, HttpStatusCode } from "axios";
export abstract class BaseApi {
    axios: Axios;
    uri: string
    constructor(uri: string, unauthorizedPostback: (() => void) | undefined = undefined) {
        this.uri = uri;
        this.axios = axios.create({
            baseURL: this.uri,
        })

        if (unauthorizedPostback)
            this.axios.interceptors.response.use(response => {
                return response;
            }, error => {
                if (error?.response?.status === HttpStatusCode.Unauthorized) {
                    unauthorizedPostback()
                    return error.response;
                }
                return error;
            });
    }

    updateToken(token: string | null) {
        if (!token)
            this.axios.defaults.headers.common['Authorization'] = null;
        else
            this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}