import { PredictionResponse } from "../interfaces";
import { BaseApi } from "./baseApi";
export class PredictionApi extends BaseApi {
    async predict(id: number, specific_request: string | null = null):Promise<PredictionResponse> {
        const data = {
            specific_request: specific_request
        }
        const url = `/games/${id}/predict`
        const response = await this.axios.post<PredictionResponse>(url, data)

        if (response.status !== 200)
            throw new Error(response.statusText)
        const responseData = response.data;
        return responseData;
    }
}