import React
  from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppProvider } from '@toolpad/core/AppProvider';
import { Button, Container } from '@mui/material';

interface HomeProps {
}

const Home: React.FC<HomeProps> = () => {

  const theme = useTheme();

  return (
    <AppProvider theme={theme}>
      <Container maxWidth="lg">
        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <img src="/logo512.png" alt="Sportbeater logo" style={{maxWidth:"90vw"}} />
          <p style={{ marginTop: "40px" }}>
            <Button component={Link} to="/login" variant="contained" color="primary">Login</Button>
          </p>
          <h1>Welcome to Sportbeater: Your AI Sports Betting Companion</h1>
          <h2>AI-Powered Betting Predictions</h2>
        </div>
      </Container>
    </AppProvider>
  );

}

export default Home;
