import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Link as RouterLink, Navigate, Outlet, BrowserRouter } from 'react-router-dom';
import { StaticRouter } from "react-router-dom/server";
import Login from './components/Login';
import GameList from './components/GameList';
import GameDetails from './components/GameDetails';
import UserProfile from './components/UserProfile';
import { User } from './interfaces';
import { signOut } from 'firebase/auth'

import { auth } from './firebase';
import { Api } from './services/api'
import { AppBar, Avatar, Box, Button, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material';
import Home from './components/Home';
import { PredictionApi } from './services/predictions';
import { AuthApi } from './services/auth';
import { Logout } from '@mui/icons-material';

import './App.scss';

const apiUri = process.env.REACT_APP_BACKEND_URI;
if (!apiUri) throw new Error("REACT_APP_BACKEND_URI not set")
const predictionUri = process.env.REACT_APP_PREDICTION_BACKEND_URI
if (!predictionUri) throw new Error("REACT_APP_PREDICTION_BACKEND_URI not set")
const authUri = process.env.REACT_APP_AUTH_BACKEND_URI
if (!authUri) throw new Error("REACT_APP_AUTH_BACKEND_URI not set")

function Router(props: { children?: React.ReactNode }) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
}

const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  if (!isAuthenticated) return <Navigate to="/" />
  return <Outlet />;
}

function App() {
  const [loaded, setLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const unauthorizedPostback = () => {
    handleLogout();
  }

  const [api] = useState<Api>(new Api(apiUri!, unauthorizedPostback))
  const [predictionApi] = useState<PredictionApi>(new PredictionApi(predictionUri!, unauthorizedPostback))
  const [authApi] = useState<AuthApi>(new AuthApi(authUri!, unauthorizedPostback))

  const updateToken = useCallback((token: string) => {
    api.updateToken(token);
    predictionApi.updateToken(token);
    setIsAuthenticated(true);
    setUser({
      email: "test@gmail.com",
      username: "test",
      id: 12123
    })
  }, [api, predictionApi])
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      updateToken(token)
    }
    setLoaded(true)
  }, [api, predictionApi, authApi, updateToken]);

  const handleLogin = (token: string) => {
    localStorage.setItem('token', token);
    updateToken(token)
  };

  const handleLogout = () => {
    signOut(auth);
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  type MenuType = {
    name: string;
    func?: () => void;
    link?: string;
  }
  const menu: MenuType[] = [{ link: "/games", name: "Games" }]
  const loggedOutMenu: MenuType[] = [{ link: "/login", name: "Login" }, { link: "/register", name: "Register" }]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return loaded ?
    <Router>
      <div className="App">
        {isAuthenticated &&
          <AppBar position="static">
            <Container maxWidth="lg">
              <Toolbar disableGutters>
                {
                  isAuthenticated ?
                    <>

                      {menu.map(m =>
                        <Box key={m.name} sx={{ flexGrow: 1, display: 'flex' }}>
                          {m.func ?
                            <Button className="App-link" onClick={m.func} sx={{ my: 2, color: 'white', display: 'block' }}>{m.name}</Button> :
                            <Button component={RouterLink} className="App-link" to={m.link || ""} sx={{ my: 2, color: 'white', display: 'block' }}>{m.name}</Button>
                          }
                        </Box>)
                      }
                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        slotProps={{
                          paper: {
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                              },
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <MenuItem to={"/profile"} component={RouterLink} onClick={handleClose}>
                          <Avatar /> My account
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>
                      </Menu>
                    </>
                    : loggedOutMenu.map(m =>
                      <Box key={m.name} sx={{ flexGrow: 1, display: 'flex' }}>
                        {m.func ?
                          <Button className="App-link" onClick={m.func} sx={{ my: 2, color: 'white', display: 'block' }}>{m.name}</Button> :
                          <Button component={RouterLink} className="App-link" to={m.link || ""} sx={{ my: 2, color: 'white', display: 'block' }}>{m.name}</Button>
                        }
                      </Box>)
                }
              </Toolbar>
            </Container>
          </AppBar>
        }
        <Container maxWidth="lg" sx={{ mt: 2 }}>
          <Routes>
            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
              <Route path="games" element={<GameList api={api} />} />
              <Route path="games/:gameId" element={<GameDetails predictionApi={predictionApi} api={api} />} />

              <Route path="/profile" element={<UserProfile user={user} />} />
            </Route>

            <Route path="/" element={isAuthenticated ? <Navigate to="games" replace={true} /> : <Home />} />
            <Route path="/login" element={<Login authApi={authApi} onLogin={handleLogin} />} />

          </Routes>
        </Container>
      </div>
      <div className="Disclaimer">
        Please note: All content on Sportbeater is intended for entertainment purposes only. We do not offer real-money gambling or betting services. Users are responsible for ensuring compliance with local laws regarding sports betting. Gamble responsibly.
      </div>
    </Router>

    : <></>
}

export default App;