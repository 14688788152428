import React from 'react';
import { User } from '../interfaces';

function UserProfile({ user }: { user: User | null }) {
    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>User Profile</h1>
            <p>Username: {user.username}</p>
            <p>Email: {user.email}</p>
            {/* Add more user details as needed */}
        </div>
    );
}

export default UserProfile;
