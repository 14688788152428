// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration


var projectId = process.env.REACT_APP_GCLOUD_PROJECT;
var apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
var messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
var appId = process.env.REACT_APP_FIREBASE_APP_ID;
var measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId: projectId,
  storageBucket: `${projectId}.firebasestorage.app`,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

if (process.env.NODE_ENV === "production") {
  getAnalytics(app);
}
if (process.env.NODE_ENV === "development") {
  // connectFirestoreEmulator(firestore, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default app;