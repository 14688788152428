import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppProvider } from '@toolpad/core/AppProvider';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../firebase';
import { AuthProvider, AuthResponse, SignInPage } from '@toolpad/core';
import { AuthApi } from '../services/auth';
const provider = new GoogleAuthProvider();

// preview-start
const providers = [
  { id: 'google', name: 'Google' },
];

interface LoginProps {
  authApi: AuthApi
  onLogin: (token: string) => void;
}

const BRANDING = {
  logo: (
    <img
      src="./logo192.png"
      alt="Sportbeater logo"
      style={{ height: 24 }}
    />
  ),
  title: 'Sportbeater',
};

const Login: React.FC<LoginProps> = ({ authApi, onLogin }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const signIn: (provider: AuthProvider) => Promise<AuthResponse> = async (p) => {
    const promise = new Promise<AuthResponse>((resolve) => {
      if (p.id === 'google') {
        signInWithPopup(auth, provider)
          .then(async (result) => {
            var token = await result.user.getIdToken();
            // The signed-in user info.

            const accessToken = await authApi.verifyToken(token);
            onLogin(accessToken!);
            navigate('/games'); // Redirect to games after successful login
            // IdP data available using getAdditionalUserInfo(result)
            resolve({})
            // ...
          }).catch((error) => {
            resolve({ error: error.message })
          });
      }
      else {
        resolve({ error: "Login type not supported." })
      }
    })

    return promise;
  };
  return (
    <AppProvider branding={BRANDING} theme={theme}>
      <SignInPage signIn={signIn} providers={providers} />
    </AppProvider>
  );

}

export default Login;
