import { BaseApi } from "./baseApi";
export class Api extends BaseApi {
    async getGames(week: number) {  
        const response = await this.axios.get(`/weeks/${week}/games`)

        if (response.status !== 200)
            throw new Error(response.statusText)
        return response.data;
    }

    async getGame(id: number) {
        const response = await this.axios.get(`/games/${id}`)

        if (response.status !== 200)
            throw new Error(response.statusText)
        return response.data;
    }

    async getOdds(id: number) {
        const response = await this.axios.get(`/games/${id}/odds`)

        if (response.status !== 200)
            throw new Error(response.statusText)
        return response.data;
    }

    async getAlternateOdds(id: number) {
        const response = await this.axios.get(`/games/${id}/alternateOdds`)

        if (response.status !== 200)
            throw new Error(response.statusText)
        return response.data;
    }
}